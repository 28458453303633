@use './mixins/responsive' as mx;
@use './init/variables' as var;

main {
 
  margin: 2rem 10rem;
  @include mx.responsive(smalldesk) {
    margin: 2rem 5rem;
  }

  @include mx.responsive(mobile) {
    margin: 2rem 1rem;
  }
}
