@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url('styles/init/fonts/Graphik-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url('styles/init/fonts/Graphik-Regular-Web.woff2') format('woff2');
}
@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url('styles/init/fonts/Graphik-Semibold-Web.woff2') format('woff2');
}
@font-face {
  font-family: 'PublicoBanner';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url('styles/init/fonts/PublicoBanner-Bold-Web.woff2') format('woff2');
}
@font-face {
  font-family: 'PublicoBanner';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url('styles/init/fonts/PublicoBanner-Medium-Web.woff2') format('woff2');
}
@font-face {
  font-family: 'PublicoBanner';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url('styles/init/fonts/PublicoBanner-Regular-Web.woff2') format('woff2');
}
