@mixin responsive($size) {
  @if ($size == smalldesk) {
    @media only screen and (max-width: 1025px) {
      @content;
    }
  }
  @if ($size == laptop) {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @if ($size == mobile) {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }
}
