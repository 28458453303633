@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("Graphik-Medium.540daab8.woff2") format("woff2");
}
@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("Graphik-Regular-Web.6dc81ae5.woff2") format("woff2");
}
@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("Graphik-Semibold-Web.c4cb2aa3.woff2") format("woff2");
}
@font-face {
  font-family: "PublicoBanner";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("PublicoBanner-Bold-Web.0cf15079.woff2") format("woff2");
}
@font-face {
  font-family: "PublicoBanner";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("PublicoBanner-Medium-Web.2dd6dff8.woff2") format("woff2");
}
@font-face {
  font-family: "PublicoBanner";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("PublicoBanner-Regular-Web.027d6b23.woff2") format("woff2");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body,
#root {
  height: 100%;
  font-size: 16px;
}
*,
button,
input {
  border: 0;
  outline: 0;
  font-family: "Graphik", Helvetica, sans-serif;
}
input[type=text] {
  font-size: 1.2rem;
  margin: 6px 0;
  text-align: left;
  outline: 0;
  border: 2px solid #999;
  padding: 0.625rem;
  border-radius: 0.525rem;
}
input[type=text]:focus {
  outline: 0;
  border: 2px solid #316eef;
  box-shadow: 2px 9px 10px 1px rgba(130, 130, 130, 0.35);
}
input[type=text]:invalid {
  outline: 0;
  border: 2px solid #db4c13;
  box-shadow: none;
}
button {
  outline: 0;
  padding: 0.625rem 0 0.8rem 0;
  text-align: center;
  font-size: 1.5rem;
  width: 100%;
  border-radius: 0.525rem;
  cursor: pointer;
  box-shadow: none;
  transition: box-shadow 0.1s ease-in;
}
button.btn__primary {
  color: #fff;
  background-color: #316eef;
}
button.btn__primary:hover {
  box-shadow: 2px 9px 10px 1px rgba(130, 130, 130, 0.35);
}
button.btn__primary:active {
  box-shadow: none;
}
:invalid button {
  cursor: not-allowed;
}
div.app-bar {
  border: 1px solid transparent;
  box-shadow: 2px 9px 10px 1px rgba(130, 130, 130, 0.35);
}
div.app-bar h1 {
  font-family: "PublicoBanner", Helvetica, sans-serif;
  margin: 0.8125rem 10rem;
}
@media only screen and (max-width: 768px) {
  div.app-bar h1 {
    margin: 0.8125rem 1rem;
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
  div.app-bar h1 {
    margin: 0.8125rem 1rem;
    text-align: center;
  }
}
.input-field {
  margin: 1.25rem 0;
}
.input-field .input-field__label {
  display: block;
  margin-bottom: 0.9375rem;
}
.input-field .input-field__prefix {
  display: inline-block;
  padding-left: 1.5rem;
  border-radius: 0.525rem;
  border: 2px solid #999;
  width: 100%;
  font-size: 1.2rem;
  height: 50px;
}
.input-field .input-field__prefix.focus {
  border: 2px solid #316eef;
  box-shadow: 2px 9px 10px 1px rgba(130, 130, 130, 0.35);
}
.input-field .input-field__field {
  margin: 0;
  border: 0;
  width: calc(100% - 2rem);
  text-align: left;
  padding-left: 0.3125rem;
}
.input-field .input-field__field:invalid {
  border: none;
}
.input-field .input-field__field:focus {
  border: 0;
  box-shadow: none;
}
.input-field .input-field__message {
  visibility: hidden;
  margin-top: 0.375rem;
  font-size: 0.825rem;
  display: block;
  color: #db4c13;
}
:invalid .input-field__prefix {
  border-color: #db4c13;
  box-shadow: none;
}
:invalid .input-field__prefix.focus {
  border-color: #db4c13;
  box-shadow: 2px 9px 10px 1px rgba(130, 130, 130, 0.35);
}
:invalid .input-field__message {
  visibility: visible;
}
:valid .input-field__prefix {
  border-color: #999;
}
:valid .input-field__message {
  visibility: hidden;
}
.app-slider {
  display: flex;
  flex-direction: column;
}
.app-slider input[type=range] {
  height: 42px;
  -webkit-appearance: none;
  text-align: center;
  margin: 0.625rem 0;
}
.app-slider input[type=range]:focus {
  outline: none;
}
.app-slider input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1.125rem;
  cursor: pointer;
  box-shadow: 0px 0px 1px #000000;
  background: #1b3979;
  border-radius: 0.5625rem;
}
.app-slider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #eee;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 1.125rem;
  background: #ffffff;
  cursor: pointer;
  margin-top: -0.5625rem;
}
.app-slider input[type=range]:focus::-webkit-slider-thumb {
  background: #ffffff86;
  border: 1px solid #ccc;
}
.app-slider input[type=range]:focus::-webkit-slider-runnable-track {
  background: #1b3979;
}
.app-slider input[type=range]::-moz-range-track {
  width: 100%;
  height: 1.125rem;
  cursor: pointer;
  box-shadow: 0px 0px 1px #000000;
  background: #1b3979;
  border-radius: 0.5625rem;
  border: 0px solid #000000;
}
.app-slider input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 0px solid #000000;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 1.125rem;
  background: #ffffff;
  cursor: pointer;
}
.app-slider__title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.app-slider__content {
  display: grid;
  grid-template-columns: 5rem 1fr 5rem 5.5rem;
  margin-bottom: 1rem;
}
.app-slider__content .app-slider__content__min, .app-slider__content .app-slider__content__max {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}
.app-slider__content .app-slider__content__range {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: center;
}
.app-slider__content .app-slider__content__range input[type=range] {
  width: 100%;
}
.app-slider__content .app-slider__content__range span {
  display: block;
  visibility: hidden;
  color: #db4c13;
  font-size: 0.9rem;
}
.app-slider__content .app-slider__content__range span:invalid {
  visibility: visible;
}
.app-slider__content .app-slider__content__value {
  text-align: center;
}
.result-card {
  margin: 0.625rem;
  margin-left: 0;
  display: flex;
}
.result-card__report {
  align-self: center;
  background-color: #fbfbfb;
  height: 85%;
  width: 90%;
  padding-left: 1.875rem;
}
@media only screen and (max-width: 480px) {
  .result-card__report {
    width: 100%;
  }
}
.result-card__report_group {
  margin-top: 1.875rem;
}
.result-card__report_group_label {
  display: block;
  color: #5d5d5d;
}
.result-card__report_group_label.bigger {
  font-size: 1.5rem;
}
.result-card__report_group_response {
  margin-top: 1.25rem;
  display: block;
  color: #000;
  font-size: 2rem;
  font-weight: bold;
}
.result-card__report_group_response.grey {
  color: #bbbcbc;
}
.card-calculator {
  display: grid;
  grid-template-columns: 3fr 2fr;
}
@media only screen and (max-width: 1025px) {
  .card-calculator {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 480px) {
  .card-calculator {
    grid-template-columns: 1fr;
  }
}
.card-calculator .card-calculator__main {
  margin: 0.625rem 0.625rem 1.5rem 0.625rem;
  padding: 1.5625rem;
  border-radius: 0.525rem;
  box-shadow: 2px 9px 10px 1px rgba(130, 130, 130, 0.35);
}
.card-calculator .card-calculator__separator {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
  border-bottom: 1px solid #dedede;
  width: 100%;
}
.card-calculator .card-calculator__form {
  margin: 1.5625rem 0;
}
.card-calculator .card-calculator__form_row {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 1025px) {
  .card-calculator .card-calculator__form_row {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 480px) {
  .card-calculator .card-calculator__form_row {
    grid-template-columns: 1fr;
  }
}
.card-calculator .card-calculator__form_footer {
  margin-top: 1.25rem;
}
main {
  margin: 2rem 10rem;
}
@media only screen and (max-width: 1025px) {
  main {
    margin: 2rem 5rem;
  }
}
@media only screen and (max-width: 480px) {
  main {
    margin: 2rem 1rem;
  }
}
/*# sourceMappingURL=index.b2e9645c.css.map */
